import { colors } from './Colors';
import { type ComponentProps, forwardRef, type ReactNode } from 'react';
import styled from 'styled-components';

interface CoreContainerProps extends ComponentProps<typeof Container> {
	inAppExplanation?: ReactNode;
}

const Container = styled.div<{ readonly $margin?: string; readonly $padding?: string; readonly borderRadius?: string }>`
	background-color: ${(props) => props.color || colors.white};
	border-radius: ${(props) => props.borderRadius || '8px'};
	display: flex;
	flex-direction: column;
	margin: ${(props) => props.$margin || 'initial'};
	position: relative;
	padding: ${(props) => props.$padding || 'none'};
`;

const InAppExplanationContainer = styled.div`
	position: absolute;
	inset-block-start: 10px;
	inset-inline-end: 10px;
	z-index: 1;
`;

export const CoreContainer = forwardRef<HTMLDivElement, CoreContainerProps>(function CoreContainer(
	{ children, inAppExplanation, ...other },
	ref,
) {
	return (
		<Container ref={ref} {...other}>
			{children}
			{inAppExplanation && <InAppExplanationContainer>{inAppExplanation}</InAppExplanationContainer>}
		</Container>
	);
});
