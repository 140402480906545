interface CoreTextProperties {
	readonly size: string;
	readonly smallSize?: string;
	readonly weight?: string;
	readonly textDecoration?: string;
	readonly textTransform?: string;
	readonly fontStyle?: string;
	readonly lineHeight?: string;
}

const title1: CoreTextProperties = {
	smallSize: '16px',
	size: '20px',
	weight: 'normal',
	textDecoration: 'initial',
	textTransform: 'initial',
	fontStyle: 'initial',
};

const title1Bold: CoreTextProperties = {
	...title1,
	weight: 'bold',
};

const title2: CoreTextProperties = {
	size: '16px',
	smallSize: '14px',
	weight: 'normal',
	textDecoration: 'initial',
	textTransform: 'initial',
	fontStyle: 'initial',
};

const title2Bold: CoreTextProperties = {
	...title2,
	weight: 'bold',
};

const title2BoldUppercase: CoreTextProperties = {
	...title2Bold,
	textTransform: 'uppercase',
};

const title3: CoreTextProperties = {
	size: '14px',
	smallSize: '12px',
	weight: 'normal',
	textDecoration: 'initial',
	textTransform: 'initial',
	fontStyle: 'initial',
};

const title3Bold: CoreTextProperties = {
	...title3,
	weight: 'bold',
};

const title3BoldUppercase: CoreTextProperties = {
	...title3Bold,
	textTransform: 'uppercase',
};

const body1: CoreTextProperties = {
	size: '14px',
	weight: 'normal',
	textDecoration: 'initial',
	textTransform: 'initial',
	fontStyle: 'initial',
	smallSize: '12px',
};

const body1Medium: CoreTextProperties = {
	...body1,
	weight: '500',
};

const body1Bold: CoreTextProperties = {
	...body1,
	weight: 'bold',
};

const body1Bolder: CoreTextProperties = {
	...body1,
	weight: '500',
};

const body1Underline: CoreTextProperties = {
	...body1,
	textDecoration: 'underline',
};

const body1UnderlineItalic: CoreTextProperties = {
	...body1,
	textDecoration: 'underline',
	fontStyle: 'italic',
};

const body1BoldUnderline: CoreTextProperties = {
	...body1Bold,
	...body1Underline,
};

const body1Italic: CoreTextProperties = {
	...body1,
	fontStyle: 'italic',
};

const body2Base: CoreTextProperties = {
	size: '12px',
	weight: 'normal',
	textDecoration: 'initial',
	textTransform: 'initial',
	fontStyle: 'initial',
};

const body2: CoreTextProperties = {
	...body2Base,
	smallSize: '10px',
};

const body3: CoreTextProperties = {
	size: '10px',
	smallSize: '10px',
};

const body2Bold: CoreTextProperties = {
	...body2Base,
	weight: 'bold',
};

const body2Underline: CoreTextProperties = {
	...body2,
	textDecoration: 'underline',
};

const subtext: CoreTextProperties = {
	size: '12px',
	weight: 'normal',
	textDecoration: 'initial',
	textTransform: 'initial',
	fontStyle: 'initial',
	smallSize: '10px',
};

const subtextMedium: CoreTextProperties = {
	...subtext,
	weight: '500',
};

const subtextBold: CoreTextProperties = {
	...subtext,
	weight: 'bold',
};

const subtextItalic: CoreTextProperties = {
	...subtext,
	fontStyle: 'italic',
	smallSize: '9px',
};

const italic: CoreTextProperties = {
	...body1,
	fontStyle: 'italic',
};

const link: CoreTextProperties = {
	size: '10px',
	weight: 'normal',
	textDecoration: 'underline',
	textTransform: 'initial',
	fontStyle: 'initial',
};

const newFeature: CoreTextProperties = {
	size: '12px',
	weight: 'semibold',
	textDecoration: 'initial',
	textTransform: 'initial',
	fontStyle: 'italic',
};

const captionRegular: CoreTextProperties = {
	size: '10px',
	weight: 'normal',
	textDecoration: 'initial',
	textTransform: 'initial',
	fontStyle: 'initial',
	lineHeight: '12px',
};

const captionRegularItalic: CoreTextProperties = {
	...captionRegular,
	fontStyle: 'italic',
};

const captionRegularItalicMedium: CoreTextProperties = {
	...captionRegularItalic,
	weight: '500',
};

const captionRegularUnderline: CoreTextProperties = {
	...captionRegular,
	textDecoration: 'underline',
};

const captionSettings: CoreTextProperties = {
	size: '8px',
	weight: 'normal',
	textDecoration: 'initial',
	textTransform: 'initial',
	fontStyle: 'initial',
	lineHeight: '10px',
};

const captionHeader: CoreTextProperties = {
	size: '12px',
	smallSize: '10px',
	weight: '500',
	textDecoration: 'initial',
	textTransform: 'initial',
	fontStyle: 'initial',
	lineHeight: '15px',
};

const captionInsights: CoreTextProperties = {
	size: '12px',
	smallSize: '10px',
	weight: 'normal',
	textDecoration: 'initial',
	textTransform: 'initial',
	fontStyle: 'initial',
	lineHeight: '13px',
};

const captionAllDocs: CoreTextProperties = {
	size: '13px',
	smallSize: '10px',
	weight: 'normal',
	textDecoration: 'initial',
	textTransform: 'initial',
	fontStyle: 'initial',
	lineHeight: '12px',
};

const note: CoreTextProperties = {
	size: '12px',
	lineHeight: '15px',
	fontStyle: 'italic',
	weight: '500',
};

export const types = {
	title1,
	title1Bold,
	title2,
	title2Bold,
	title2BoldUppercase,
	title3,
	title3Bold,
	title3BoldUppercase,

	body1,
	body1Medium,
	body1Bold,
	body1Bolder,
	body1Underline,
	body1UnderlineItalic,
	body1BoldUnderline,
	body1Italic,
	body2,
	body2Underline,
	body2Bold,
	body3,

	subtext,
	subtextMedium,
	subtextBold,
	subtextItalic,
	italic,

	link,
	newFeature,
	note,

	captionRegular,
	captionRegularUnderline,
	captionRegularItalic,
	captionRegularItalicMedium,
	captionSettings,
	captionHeader,
	captionInsights,
	captionAllDocs,
} as const;

export type TextColorType =
	| 'primary'
	| 'secondary'
	| 'tertiary'
	| 'white'
	| 'accentOrange'
	| 'accentGreen'
	| 'accentLightGreen'
	| 'brightLink'
	| 'dark'
	| 'textGrey'
	| 'black'
	| 'lightGrey'
	| 'colorInfoBase'
	| 'colorTextDescription'
	| 'link'
	| 'darkCyan';

export type CoreTextType =
	| 'title1'
	| 'title1Bold'
	| 'title2'
	| 'title2Bold'
	| 'title2BoldUppercase'
	| 'title3'
	| 'title3Bold'
	| 'title3BoldUppercase'
	| 'body1'
	| 'body1Medium'
	| 'body1Bold'
	| 'body1Bolder'
	| 'body1BoldUnderline'
	| 'body1UnderlineItalic'
	| 'body1Underline'
	| 'body1Italic'
	| 'body2'
	| 'body2Bold'
	| 'body2Underline'
	| 'body3'
	| 'subtext'
	| 'subtextMedium'
	| 'subtextBold'
	| 'subtextItalic'
	| 'italic'
	| 'link'
	| 'newFeature'
	| 'captionRegular'
	| 'captionRegularItalic'
	| 'captionRegularItalicMedium'
	| 'captionRegularUnderline'
	| 'captionSettings'
	| 'captionHeader'
	| 'captionInsights'
	| 'captionAllDocs'
	| 'note';
