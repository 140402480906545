import { getThemeStringValueByName } from '../../utilities/styling/UseCssVarByName';
import { getOrCreateAnalytics } from '../../utils/analytics';
import { Typography, theme } from 'antd';
import { observer } from 'mobx-react-lite';
import type { ReactNode } from 'react';
import styled from 'styled-components';

const IconContainer = styled.div<{ readonly $isSelected: boolean }>`
	display: flex;
	align-items: center;
	justify-content: center;
	border-radius: 4px;
	margin-inline-end: 14px;
	background-color: ${(props) => getThemeStringValueByName(props.$isSelected ? 'purple.1' : 'colorFillSecondary')};
	border: ${(props) => (props.$isSelected ? `1px solid ${getThemeStringValueByName('purple.2')}` : 'none')};
`;

const SelectionIndicator = styled.div<{ readonly $isSelected: boolean }>`
	max-inline-size: 3px;
	min-inline-size: 3px;
	block-size: 46px;
	border-end-end-radius: 5px;
	margin-inline-end: 5px;
	background-color: ${(props) => (props.$isSelected ? getThemeStringValueByName('colorPrimary') : 'transparent')};
`;

const TextContainer = styled.div`
	display: flex;
	flex-direction: column;
`;

const HoverContainer = styled.div`
	display: inline-flex;
	align-items: center;
	justify-content: center;
	border-radius: 6px;
	padding: 7px;
`;

const Container = styled.a`
	display: inline-flex;
	align-items: center;
	justify-content: flex-start;
	border-block-start: 1px solid #e8e8e8;

	&:hover {
		background-color: ${getThemeStringValueByName('controlItemBgHover')};
	}
`;

interface PopoverItemSmallProps {
	readonly title: string;
	readonly description?: string;
	readonly link: string;
	readonly icon: ReactNode;
	readonly isSelected: boolean;
	readonly analyticsExtras: any;
}

export const PopoverItemSmall = observer(function PopoverItemSmall({
	title,
	link,
	icon,
	isSelected,
	analyticsExtras,
}: PopoverItemSmallProps) {
	const onClickAnalytic = (): Promise<void> =>
		getOrCreateAnalytics().track(getOrCreateAnalytics().idsNames.SummaryClick, analyticsExtras);

	const { token } = theme.useToken();

	return (
		<Container href={link} onClick={onClickAnalytic}>
			<SelectionIndicator $isSelected={isSelected} />
			<HoverContainer>
				<IconContainer $isSelected={isSelected}>{icon}</IconContainer>
				<TextContainer>
					<Typography.Text strong style={{ color: isSelected ? token.colorText : token.colorTextLabel }}>
						{title}
					</Typography.Text>
				</TextContainer>
			</HoverContainer>
		</Container>
	);
});
