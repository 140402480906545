import { GeneralMessage } from './GeneralMessage';
import { IS_PRODUCTION } from './config/Environment';
import { SummaryLayout } from './react-router/layouts/SummaryLayout';
import { getOrCreateApiTraceServer } from './server/apiTrace';
import { getOrCreateAnalytics } from './utils/analytics';
import { getOrCreateLogger } from './utils/logger';
import LogRocket from 'logrocket';
import { Component, type ReactNode } from 'react';

interface State {
	readonly hasError: boolean;
}

interface ErrorBoundaryProps {
	readonly children: ReactNode;
}

// eslint-disable-next-line no-restricted-syntax -- Error boundaries only work with classes.
export class ErrorBoundary extends Component<ErrorBoundaryProps, State> {
	state = { hasError: false };

	logErrorToMyService = (error: Error, info: unknown): void => {
		if (IS_PRODUCTION) {
			// TODO - save those logs elsewhere
			getOrCreateApiTraceServer()
				.logError('error_in_frontend', error, info)
				.catch(function logToConsole(loggingError): void {
					console.log('error logging the event', loggingError);
				});

			getOrCreateLogger().error('Error boundary', { type: 'error_in_frontend', error, info });
			console.log('Error boundary', { type: 'error_in_frontend', error, info });

			getOrCreateAnalytics().track(getOrCreateAnalytics().idsNames.Error, { error, info });
		}
	};

	componentDidCatch = (error: Error, info: unknown): void => {
		LogRocket.captureException(error);
		// Display fallback UI
		this.setState({ hasError: true });
		// You can also log the error to an error reporting service
		console.log(error, info);
		this.logErrorToMyService(error, info);
	};

	render = () => {
		if (this.state.hasError) {
			// You can render any custom fallback UI
			localStorage.setItem('hadError', 'true');
			window.location.href = window.location.origin;
			return <SummaryLayout component={GeneralMessage} design={null} />;
		}
		return this.props.children;
	};
}
