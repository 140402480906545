import { colors } from './Colors';
import { type CoreTextType, type TextColorType, types } from './CoreTextTypes';
import { MAX_WIDTH_LOWER_WIDTH } from './Resolutions';
import { useRef, useState, useEffect, type ComponentProps } from 'react';
import styled from 'styled-components';

export interface CoreTextProps extends ComponentProps<'span'> {
	type: CoreTextType;
	color?: TextColorType;
	text?: string;
	$margin?: string;
	$padding?: string;
	$maxWidth?: string;
	$whiteSpace?: string;
	$textAlign?: string;
	onClick?: any;
	cursor?: string;
	$position?: string;
	borderBottom?: string;
	transition?: string;
	boxSizing?: string;
	fsExclude?: boolean;
	isOneLine?: boolean;
	background?: string;
	$lineHeight?: string;
	visibility?: string;
	$minHeight?: string;
	maxHeight?: string;
	$hoverBackgroundColor?: string;
	width?: string;
	$weight?: string;
	whiteSpace?: string;
	maxWidth?: string;
	opacity?: string;
	overflow?: string;
	display?: string;
	$alignItems?: string;
}

const StyledCoreText = styled.span<CoreTextProps>`
	font-size: ${(props) => types[props.type].size};

	@media ${MAX_WIDTH_LOWER_WIDTH} {
		font-size: ${(props) => types[props.type].smallSize || types[props.type].size};
	}

	font-weight: ${(props) => types[props.type].weight};
	color: ${(props) => colors[props.color]};
	margin: ${(props) => props.$margin || 'initial'};
	padding: ${(props) => props.$padding || 'initial'};
	max-inline-size: ${(props) => props.$maxWidth || 'none'};
	white-space: ${(props) => (props.$whiteSpace || props.isOneLine ? 'nowrap' : 'initial')};
	text-overflow: ${(props) => (props.$maxWidth ? 'ellipsis' : 'initial')};
	overflow: ${(props) => (props.$maxWidth || props.isOneLine ? 'hidden' : 'initial')};
	text-transform: ${(props) => types[props.type].textTransform};
	cursor: ${(props) => props.cursor || 'inherit'};
	text-decoration: ${(props) => types[props.type].textDecoration};
	position: ${(props) => props.$position || 'initial'};
	text-align: ${(props) => props.$textAlign || 'initial'};
	border-block-end: ${(props) => props.borderBottom || 'initial'};
	box-sizing: ${(props) => props.boxSizing || 'initial'};
	transition: ${(props) => props.transition || 'initial'};
	background: ${(props) => props.background || 'initial'};
	font-style: ${(props) => types[props.type].fontStyle};
	line-height: ${(props) => props.$lineHeight || 'initial'};
	visibility: ${(props) => props.visibility};

	min-block-size: ${(props) => props.$minHeight || 'fit-content'};
	block-size: fit-content;
	max-block-size: ${(props) => props.maxHeight || 'fit-content'};
	inline-size: fit-content;
	font-family: Inter, sans-serif;

	${(props) =>
		(props.color === 'link' || props.color === 'brightLink') &&
		`
			&:hover,
			a {
				text-decoration: underline;
			}

			a {
				color: ${colors[props.color]};
				text-decoration: none;
			}
		`}

	${(props) => `
		&:hover,
		a {
			background: ${props.$hoverBackgroundColor};
		}
	`};
`;

export function CoreText({
	type,
	text,
	children,
	color = 'primary',
	$weight = 'normal',
	onClick,
	fsExclude = true,
	cursor,
	whiteSpace,
	maxWidth,
	className,
	...other
}: CoreTextProps) {
	const componentRef = useRef<HTMLSpanElement>();
	const [isWrapped, setIsWrapped] = useState(false);

	useEffect((): void => {
		if (componentRef.current && componentRef.current.scrollWidth > componentRef.current.clientWidth) {
			setIsWrapped(true);
		}
	}, []);

	if (color === 'brightLink') {
		cursor = 'pointer';
	}

	return (
		<StyledCoreText
			onClick={onClick}
			ref={componentRef}
			type={type}
			$weight={$weight}
			color={color}
			title={isWrapped ? text : undefined}
			className={`${className} ${fsExclude ? 'fs-exclude' : ''}`}
			cursor={cursor}
			$whiteSpace={whiteSpace}
			$maxWidth={maxWidth}
			{...other}
		>
			{text || children}
		</StyledCoreText>
	);
}
