import { CoreText } from '../../../base-ui/CoreText';
import { AddonClickBehaviorChooser } from './AddonClickBehaviorChooser';
import { observer } from 'mobx-react-lite';
import styled from 'styled-components';

const Container = styled.div`
	display: flex;
	flex-direction: column;
`;

const StyledCaptionText = styled(CoreText)`
	inline-size: 100%;
	margin-block: 12px;
	margin-inline: 0;
`;

export const UserSettings = observer(function UserSettings() {
	return (
		<Container>
			<CoreText type='body1BoldUnderline'>Settings</CoreText>
			<StyledCaptionText type='captionHeader'>Set how Navina will be opened from the EMR</StyledCaptionText>
			<AddonClickBehaviorChooser />
		</Container>
	);
});
