import { getOrCreateAnalytics } from '../utils/analytics';
import QueryString from 'query-string';
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

export function useUrlChanged() {
	const location = useLocation();

	const analytics = getOrCreateAnalytics();

	useEffect((): void => {
		const parsedQueries = QueryString.parse(window.location.search);

		const src = Array.isArray(parsedQueries.src) ? parsedQueries.src[0] : parsedQueries.src;
		const openedAs = Array.isArray(parsedQueries.openedAs) ? parsedQueries.openedAs[0] : parsedQueries.openedAs;

		analytics.setAnalyticTrack({
			fullScreen: !window.location.href.includes('overlay'),
			...(src && { src }),
			...(openedAs && { openedAs }),
		});
	}, [location]);
}
