import {
	EPIC_CLIENT_ID_PRODUCTION,
	OLD_EPIC_CLIENT_ID_PRODUCTION,
	OLD_EPIC_CONNECTION_NAMES,
} from '../../pages/epic/consts';
import { CommonConfiguration } from '../CommonConfig';
import type { NavinaConfiguration } from '../NavinaConfiguration';

const Auth0ClientID = 'JZfc5ja9sruLNhLtXnLRy5yua5kX9USO';

export const ProductionConfiguration = {
	...CommonConfiguration,
	ApiUrl: 'https://ff6ywy42f3.execute-api.us-east-1.amazonaws.com/default/',
	Auth0ClientID,
	NavinaAuth0AuthURL: (redirectUri: string = CommonConfiguration.Auth0LoginRedirect) =>
		`${CommonConfiguration.NavinaAuth0BaseURL}authorize?response_type=id_token%20token&client_id=${Auth0ClientID}&prompt=none&redirect_uri=${redirectUri}&nonce=${CommonConfiguration.Auth0Nonce}&scope=offline_access`,
	EpicClientId: (connectionName: string) =>
		OLD_EPIC_CONNECTION_NAMES.includes(connectionName.toLowerCase())
			? OLD_EPIC_CLIENT_ID_PRODUCTION
			: EPIC_CLIENT_ID_PRODUCTION,
	WebSocketServerApiId: '5w2w0zssmj',
	WebSocketServerStage: 'production',
	MixpanelHost: ['https://tracking-proxy.production.navina.ai/', ...CommonConfiguration.MixpanelHost],
} as const satisfies NavinaConfiguration;
