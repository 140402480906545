import { CoreText } from '../../base-ui/CoreText';
import type { CoreTextType, TextColorType } from '../../base-ui/CoreTextTypes';
import type { DemographicsType, StatusProps } from '../../types';
import moment from 'moment';
import {
	type ReactNode,
	type RefObject,
	type FocusEvent as ReactFocusEvent,
	useEffect,
	useMemo,
	useState,
} from 'react';

export const MOMENT_INVALID_DATE = 'Invalid date';

export const formatDate = (dateStr: string): string => {
	if (!dateStr) {
		return null;
	}

	const date = new Date(dateStr);
	const month = date.getMonth() + 1; // Stupid JS month is 0 based
	let monthStr = month.toString();
	if (month < 10) {
		monthStr = `0${monthStr}`;
	}
	return `${monthStr}/${date.getFullYear()}`;
};

export const formatDayMonth = (dateStr: string): string => {
	if (!dateStr) {
		return null;
	}
	const date = new Date(dateStr);
	const month = date.getMonth() + 1; // Stupid JS month is 0 based
	const monthStr = `0${month.toString()}`.slice(-2);
	const dayStr = `0${date.getDate()}`.slice(-2);
	return `${monthStr}/${dayStr}`;
};

export const formatFullDate = (date): string => {
	if (date === undefined || !date) {
		return '';
	}
	return moment(date).format('MM/DD/YYYY');
};

export const formatFullDateWithShortYear = (date): string => {
	if (date === undefined || !date) {
		return '';
	}
	return moment(date).format('MM/DD/YY');
};

interface RegularCellTextProps {
	children: ReactNode;
	color?: TextColorType;
	textType?: CoreTextType;
}

export const RegularCellText = ({ color, textType, ...other }: RegularCellTextProps) => (
	<CoreText type={textType || 'body1'} color={color} $position='relative' {...other} />
);

export const getSmokingStatus = (isSmoker: boolean, isFormerSmoker: boolean) => {
	// isSmoker and isFormerSmoker can be None, so we explicitly check if '== true'
	if (isSmoker) {
		return 'Smoker';
	}
	if (isFormerSmoker) {
		return 'Former smoker';
	}
	if (!isSmoker || !isFormerSmoker) {
		return 'Non smoker';
	}

	return 'Unknown smoker status';
};

export const checkIfSummaryIsInvalid = (data: StatusProps & { readonly demographics?: DemographicsType }): boolean => {
	if (!data) {
		// no data has arrived at all? probably still loading
		return false;
	}

	return (
		((!data?.crawlerStatus || !data?.etlStatus) && moment(data?.modified).isBefore(moment().subtract(60, 'days'))) ||
		!data?.automatedSummaryStatus ||
		(Boolean(data.demographics) && Boolean(data.demographics.age) && data?.demographics?.age < 18)
	); // We've decided to show underage patients as invalid
};

export const getSummaryInvalidMessage = (errorMessage: string | null): string => {
	if (errorMessage === 'Patient is under 18 years old') {
		return 'No available data, patient is under 18 years old';
	}
	return 'No available data on this case';
};

/** WARNING: arrays must not contain {objects} or behavior may be undefined */
export const intArraysEqual = (a1: number[], a2: number[]): boolean => {
	return JSON.stringify(a1) === JSON.stringify(a2);
};

// Get file extension by path. an empty string will return empty string. https://stackoverflow.com/a/1203361/12595469
export const getFileExtension = (filename: string) => filename.split('.').pop();

export const formatIcdCode = (icdCode: string): string => {
	if (icdCode === undefined || icdCode.includes('.')) {
		return icdCode;
	}
	if (icdCode.length > 3) {
		return `${icdCode.substring(0, 3)}.${icdCode.substring(3, icdCode.length)}`;
	}
	return icdCode;
};

// Conditionally limit the amount of decimal digits if needed
// For example, 1.2345, 3 => 1.234
//              1.2345, 2 => 1.23
//              1.2   , 2 => 1.2   (no change)
export function removeDecimalsAfter(value: number, decimalPlaces = 0): number {
	const decimalDigits = (value.toString().split('.')[1] || []).length;

	if (decimalDigits > decimalPlaces) {
		return Number(value.toString().match(new RegExp(`^\\d+(?:\\.\\d{0,${decimalPlaces}})?`)));
	}

	return value;
}

// Focus on the end of the textarea
export function setFocusAtTheEndOfTextarea(event: ReactFocusEvent<HTMLTextAreaElement, Element>): void {
	const value = event.target.value;
	event.target.value = '';
	event.target.value = value;
}

// AntD is creating some components once in initialization
// and then recycling it each time the column filter is clicked.
// We want to have an event when the component is shown
// so we can track it in analytics.
export function useOnScreen(ref: RefObject<HTMLElement>): boolean {
	const [isIntersecting, setIntersecting] = useState(false);

	const observer = useMemo(() => new IntersectionObserver(([entry]) => setIntersecting(entry.isIntersecting)), [ref]);

	useEffect((): VoidFunction => {
		observer.observe(ref.current);
		return (): void => observer.disconnect();
	}, []);

	return isIntersecting;
}
