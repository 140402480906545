export function capitalizeFirstLetter(text: string): string {
	if (text && text.length > 0) {
		return text.charAt(0).toUpperCase() + text.slice(1);
	}
	return text;
}

const observationCapitalizations = {
	bmi: 'BMI',
	ascvd: 'ASCVD',
	hba1c: 'HbA1c',
	egfr: 'eGFR',
	hdl: 'HDL',
	ldl: 'LDL',
	tg: 'TG',
	tc: 'TC',
	ft4: 'T4', // This is confusing, talk with Dr. Sheinberg
	ft4f: 'FT4', // This is confusing, talk with Dr. Sheinberg
	ft3: 'FT3',
	tsh: 'TSH',
	bun: 'BUN',
	alt: 'ALT',
	alp: 'ALP',
	ast: 'AST',
	hgb: 'HGB',
	hct: 'HCT',
	mcv: 'MCV',
	wbc: 'WBC',
	plt: 'PLT',
	pt: 'PT',
	inr: 'INR',
	fvc: 'FVC',
	fev1: 'FEV1',
	'fev1/fvc': 'FEV1/FVC',
} as const;

export const capitalizeMedTerms = (text: string): string => {
	if (!text) {
		return text;
	}

	const medTermPattern = new RegExp(`\\b${Object.keys(observationCapitalizations).join('\\b|\\b')}\\b`, 'gi');

	return text.replace(medTermPattern, function getCapitalizedTerm(matched) {
		return observationCapitalizations[matched.toLowerCase()];
	});
};

export const LabelSorter = (a: { label: string }, b: { label: string }): -1 | 0 | 1 => {
	const ALabel = typeof a.label === 'string' ? a.label.trim() : '';
	const BLabel = typeof b.label === 'string' ? b.label.trim() : '';

	if (ALabel > BLabel) {
		return 1;
	}
	if (BLabel > ALabel) {
		return -1;
	}

	return 0;
};
