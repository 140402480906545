import Icon, { type CustomIconComponentProps } from '@ant-design/icons/lib/components/Icon';

function AppsSvg({ fill = '#B0AAD4' }: { fill?: string }) {
	return (
		<svg width='17' height='16' viewBox='0 0 17 16' fill='none' xmlns='http://www.w3.org/2000/svg'>
			<path
				fillRule='evenodd'
				clipRule='evenodd'
				d='M0.5 1C0.5 0.447715 0.947715 0 1.5 0H3.5C4.05228 0 4.5 0.447715 4.5 1V3C4.5 3.55228 4.05228 4 3.5 4H1.5C0.947715 4 0.5 3.55228 0.5 3V1ZM6.5 1C6.5 0.447715 6.94772 0 7.5 0H9.5C10.0523 0 10.5 0.447715 10.5 1V3C10.5 3.55228 10.0523 4 9.5 4H7.5C6.94772 4 6.5 3.55228 6.5 3V1ZM13.5 0C12.9477 0 12.5 0.447715 12.5 1V3C12.5 3.55228 12.9477 4 13.5 4H15.5C16.0523 4 16.5 3.55228 16.5 3V1C16.5 0.447715 16.0523 0 15.5 0H13.5ZM0.5 7C0.5 6.44772 0.947715 6 1.5 6H3.5C4.05228 6 4.5 6.44772 4.5 7V9C4.5 9.55229 4.05228 10 3.5 10H1.5C0.947715 10 0.5 9.55229 0.5 9V7ZM7.5 6C6.94772 6 6.5 6.44772 6.5 7V9C6.5 9.55229 6.94772 10 7.5 10H9.5C10.0523 10 10.5 9.55229 10.5 9V7C10.5 6.44772 10.0523 6 9.5 6H7.5ZM12.5 7C12.5 6.44772 12.9477 6 13.5 6H15.5C16.0523 6 16.5 6.44772 16.5 7V9C16.5 9.55229 16.0523 10 15.5 10H13.5C12.9477 10 12.5 9.55229 12.5 9V7ZM1.5 12C0.947715 12 0.5 12.4477 0.5 13V15C0.5 15.5523 0.947715 16 1.5 16H3.5C4.05228 16 4.5 15.5523 4.5 15V13C4.5 12.4477 4.05228 12 3.5 12H1.5ZM6.5 13C6.5 12.4477 6.94772 12 7.5 12H9.5C10.0523 12 10.5 12.4477 10.5 13V15C10.5 15.5523 10.0523 16 9.5 16H7.5C6.94772 16 6.5 15.5523 6.5 15V13ZM13.5 12C12.9477 12 12.5 12.4477 12.5 13V15C12.5 15.5523 12.9477 16 13.5 16H15.5C16.0523 16 16.5 15.5523 16.5 15V13C16.5 12.4477 16.0523 12 15.5 12H13.5Z'
				fill={fill}
			/>
		</svg>
	);
}

export function AppsIcon(props: Partial<CustomIconComponentProps>) {
	return <Icon component={AppsSvg} {...props} />;
}
