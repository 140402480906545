export enum ConfigurationName {
	development = 'development',
	test = 'test',
	production = 'production',
	staging = 'staging',
	jsonServer = 'jsonServer',
	local = 'local',
}

export interface CoreConfiguration {
	readonly CognitoUserPoolId: string;
	readonly CognitoClientId: string;
	readonly CognitoLocalStorageKey: string;
	readonly NumberOfPatientsInPage: number;
	readonly MixpanelKey: string;
	readonly MixpanelHost: ReadonlyArray<string>;
	readonly AlertTime: number;
	readonly MaxPdfDocumentWidth: number;
	readonly LaunchDarklyKey: string;
	readonly Auth0LoginRedirect: string;
	readonly Auth0Nonce: string;
	readonly NavinaAuth0BaseURL: string;
}

interface AuthenticationConfiguration {
	readonly Auth0ClientID: string;
	readonly NavinaAuth0AuthURL: (redirectUri?: string) => string;
	readonly ApiUrl: string;
	readonly EpicClientId: (connectionName: string) => string;
	readonly WebSocketServerApiId: string;
	readonly WebSocketServerStage: 'staging' | 'production';
}

export interface NavinaConfiguration extends CoreConfiguration, AuthenticationConfiguration {}
