import { UNITY_ENCOUNTER_ID } from '../../utils/consts';
import { observer } from 'mobx-react-lite';
import { useEffect } from 'react';

const redirectToLogin = (): void => {
	const urlParams = new URLSearchParams(window.location.search);
	const ssoToken = urlParams.get('sso');
	localStorage.removeItem(UNITY_ENCOUNTER_ID);
	window.location.href = `${window.location.origin}/login?sso=${ssoToken}`;
};

export const VeradigmLogin = observer(function VeradigmLogin() {
	useEffect(redirectToLogin, []);
	return null;
});
