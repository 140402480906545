import { featureFlag } from '../../../config/FeatureFlag';
import { useState } from 'react';

export function useFeatureFlag<T = any>(key: string, defaultValue: T): T {
	const [state, setState] = useState<T>(defaultValue);
	const [isInitialized, setIsInitialized] = useState<boolean>(false);

	const { client } = featureFlag();
	if (client && !isInitialized) {
		client
			.waitForInitialization(5)
			.then((): void => {
				const value = featureFlag().getFeatureValue<T>(key, defaultValue);
				setState(value);
				setIsInitialized(true);
			})
			.catch((err): void => console.error('error initializing LDClient', err));
	}

	return state;
}
