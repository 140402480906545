import antdTheme from '../theme/antd-tokens/light.json';

export const colors = {
	primary: '#322B5F', // dark background and texts
	secondary: '#53479E', // texts - table headers
	tertiary: '#ADA7D7', // texts - demographics field names
	hold: '#D6D3EB',
	hover: '#EFEDF7',
	background: '#F8F8FC', // light page background
	white: '#FFFFFF', // white
	accentGreen: '#0B8E65', // text
	accentOrange: '#F24E1E', // text
	darkCyan: antdTheme['cyan.9'],
	accentOrangeLight: 'rgba(230, 80, 0, 0.4)', // pdfSelectedText
	accentYellowLight: 'rgba(230, 195, 0, 0.35)', // pdfSelectedCurrentText
	accentLightGreen: '#78F5CE', // Next patient button
	userProfileBackground: '#B8FAE5', // User profile popup green background
	dark: '#1C1835', // small button
	lightGrey: '#C2BFBF', // borders, dividers
	lightGrey2: '#FCFCFC', // pdf icd menu
	lightGrey3: '#F5F5F5', // pdf top bar
	docsGrey: '#FAFAFA',
	toggleOff: '#DBDADC',
	lightGrey4: '#ececee', // all docs tabs background
	textGrey: '#686868',
	textGrey50Opacity: '#C6C6C8',
	passwordIndicator: {
		// password strength indicator bar
		fine: '#ded69b',
		good: '#bede9b',
		strong: '#a3de9b',
		excellent: '#9bdea9',
		empty: '#ADA7D7',
	},

	antInfoIconColor: '#6454BF',
	authInputBackground: '#ECECFF',
	emptyBar: '#C5F7EA',
	fullBar: '#7AF5CE',
	featureAnnouncement: '#F8D39D',
	label: 'rgba(173, 167, 215, 0.2)',
	newHccGDx: '#98DEA9',
	notAddressedDx: '#D5F1DB',
	rejectedDx: '#E0E0E0',
	noHccDx: '#CDF1F9',
	cartStroke: '#F7F6FB',
	black: '#000000',
	successToast: '#D9F9EB',
	docLabel: '#DEDCEF',
	closeButtonHover: '#D9D9D9',
	borderGrey: '#C4C4C4',
	colorInfoBorder: '#72B0EA',
	colorInfoBase: '#006ED2',
	colorTextDescription: 'rgba(0, 0, 0, 0.45)',
	summaryNoteHeaderBackground: '#EFEDF7',
	summaryNoteHeaderText: '#7268B0',
	summaryNoteBorder: '#CFCBE6',
};
