import { type NavinaLayout } from './LayoutType';
import { observer } from 'mobx-react-lite';
import { Suspense } from 'react';

interface EmptyLayoutProps extends NavinaLayout {}

export const EmptyLayout = observer(function EmptyLayout({ component: Component, ...props }: EmptyLayoutProps) {
	return (
		<Suspense fallback={<div />}>
			<Component {...props} />
		</Suspense>
	);
});
