import styled from 'styled-components';

export const PageContainerWithoutBackground = styled.div`
	inline-size: 100%;
	block-size: 100%;
	overflow: hidden;

	background: none;
	background-size: cover;

	display: flex;
	align-items: center;
	justify-content: center;
`;
