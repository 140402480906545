import { PageContainerWithoutBackground } from '../PageContainerWithoutBackground';
import { type NavinaLayout } from './LayoutType';
import { LoadingOutlined } from '@ant-design/icons';
import { Spin } from 'antd';
import { observer } from 'mobx-react-lite';
import { Suspense } from 'react';

interface SpinnerLayoutProps extends NavinaLayout {}

export const SpinnerLayout = observer(function SpinnerLayout({
	component: Component,
	spinningForever = false,
	...props
}: SpinnerLayoutProps) {
	return (
		<PageContainerWithoutBackground>
			{spinningForever ? (
				<Suspense fallback={null}>
					<Spin indicator={<LoadingOutlined style={{ fontSize: 48 }} spin />} />
					<Component {...props} />
				</Suspense>
			) : (
				<Suspense fallback={<Spin indicator={<LoadingOutlined style={{ fontSize: 48 }} spin />} />}>
					<Component {...props} />
				</Suspense>
			)}
		</PageContainerWithoutBackground>
	);
});
