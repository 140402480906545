import { colors } from '../Colors';

interface UserIconProps {
	readonly color?: string;
}

export function UserIcon({ color = colors.tertiary }: UserIconProps) {
	return (
		<svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
			<circle r='2.25' transform='matrix(-1 0 0 1 12 8)' stroke={color} strokeWidth='1.5' />
			<path d='M6 19C6 15.6863 8.68629 13 12 13C15.3137 13 18 15.6863 18 19' stroke={color} strokeWidth='1.5' />
		</svg>
	);
}
