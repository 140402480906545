import { isPartOfEPICLoginFlow } from '../../pages/epic/epicLoginUtils';
import { useAuthStore } from '../../stores/authStore';
import { getOrCreateAddonCommunicator, OverlayProtocolTypes } from '../../utils/addon/addon-communicator';
import { addCurrentTimeUnixToLocalStorage, inIframe } from '../../utils/common';
import { NEXT_URL_QUERY_PARAMS, REDIRECTING_TO_SUMMARY_TIME } from '../../utils/consts';
import { PageContainerWithoutBackground } from '../PageContainerWithoutBackground';
import { useUrlChanged } from '../useUrlChanged';
import type { NavinaRoute } from './RouteType';
import { LoadingOutlined } from '@ant-design/icons';
import { Spin } from 'antd';
import { observer } from 'mobx-react-lite';
import { useEffect, useState } from 'react';
import { Redirect, Route } from 'react-router-dom';

const EmptyDiv = () => <div />;

interface GuestOnlyRouteProps extends NavinaRoute {}

export const GuestOnlyRoute = observer(function GuestOnlyRoute({
	layout: Layout,
	component: Component,
	location,
	...rest
}: GuestOnlyRouteProps) {
	useUrlChanged();

	const authStore = useAuthStore();

	const [loggedIn, setLoggedIn] = useState(authStore?.isLoggedIn);

	useEffect((): void => setLoggedIn(authStore?.isLoggedIn), [authStore?.isLoggedIn, authStore]);

	if (loggedIn === undefined || authStore.isInitializing) {
		return (
			<PageContainerWithoutBackground>
				<Spin indicator={<LoadingOutlined style={{ fontSize: 48 }} spin />} />
			</PageContainerWithoutBackground>
		);
	}

	if (!loggedIn) {
		return <Route render={(props) => <Layout component={Component} {...rest} {...props} />} />;
	}

	if (loggedIn) {
		let nextUrl = `${location.pathname}${location.search}`;
		nextUrl = nextUrl.replace('/login', '');
		const queryParamsNextUrl = new URLSearchParams(location.search).get(NEXT_URL_QUERY_PARAMS);
		const localStorageNextUrl = localStorage.getItem(NEXT_URL_QUERY_PARAMS);

		if (isPartOfEPICLoginFlow()) {
			addCurrentTimeUnixToLocalStorage(REDIRECTING_TO_SUMMARY_TIME);
		}

		if (localStorageNextUrl) {
			nextUrl = localStorageNextUrl;
			localStorage.removeItem(NEXT_URL_QUERY_PARAMS);

			const isInIframe = inIframe();

			if (isInIframe) {
				getOrCreateAddonCommunicator().sendToAddon(OverlayProtocolTypes['addon-change-frame-src'], {
					url: `${window.location.origin}${nextUrl}`,
				});
			}
		} else if (queryParamsNextUrl) {
			nextUrl = queryParamsNextUrl;
		}

		return <Redirect to={nextUrl} />;
	}
	return <Route {...rest} render={(props) => <Layout component={EmptyDiv} {...rest} {...props} />} />;
});
