import packageJson from '../package.json';
import { App } from './App';
import { ENVIRONMENT_NAME, IS_PRODUCTION } from './config/Environment';
import { AuthStoreContext, getOrCreateAuthStore } from './stores/authStore';
import { QuicksightStore, QuicksightStoreContext } from './stores/quicksightStore';
import { ScheduleStore, ScheduleStoreContext } from './stores/scheduleStore';
import { UserConfigStore, UserConfigStoreContext } from './stores/userConfigStore';
import antdTheme from './theme/antd-tokens/light.json';
import { ConfigProvider } from 'antd';
import LogRocket from 'logrocket';
import { StrictMode } from 'react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter as Router } from 'react-router-dom';

if (IS_PRODUCTION) {
	const { datadogRum } = await import('@datadog/browser-rum');
	datadogRum.init({
		applicationId: '1dc2f44c-0bd2-44bc-be2b-05adfeaf9acb',
		clientToken: 'pubf149b3c6ceb4ccbe92d7ffb86de31fba',
		site: 'datadoghq.com',
		service: 'navina-app',
		env: ENVIRONMENT_NAME,
		// Specify a version number to identify the deployed version of your application in Datadog
		// version: '1.0.0',
		sessionSampleRate: 100,
		sessionReplaySampleRate: 0,
		trackUserInteractions: true,
		trackResources: true,
		trackLongTasks: true,
		defaultPrivacyLevel: 'mask-user-input',
		allowedTracingUrls: [(url) => url.includes('execute-api.us-east-1.amazonaws.com')],
	});
}

if (IS_PRODUCTION) {
	LogRocket.init('oksqfj/navina', {
		release: packageJson.version,
		console: {
			shouldAggregateConsoleErrors: true,
		},
		dom: {
			textSanitizer: true,
		},
		network: {
			responseSanitizer(response): typeof response {
				if (response.headers['x-secret']) {
					// removes all response data
					return null;
				}

				// scrubs response body
				response.body = null;
				return response;
			},
		},
	});
}

const AuthStoreProvider = AuthStoreContext.Provider;
const authStore = getOrCreateAuthStore();

const pathsToSkipLoadingSchedule = ['/overlay/', 'epic/login', '/quality', '/files'] as const;

const lowercaseHref = window.location.href.toLocaleLowerCase();

const shouldSkipLoadingSchedule = pathsToSkipLoadingSchedule.some((path): boolean => lowercaseHref.includes(path));

const ScheduleStoreProvider = ScheduleStoreContext.Provider;
const scheduleStore = new ScheduleStore(authStore, !shouldSkipLoadingSchedule);

const UserConfigStoreProvider = UserConfigStoreContext.Provider;
const userConfigStore = new UserConfigStore(authStore);

const QuicksightProvider = QuicksightStoreContext.Provider;
const quicksightStore = new QuicksightStore(authStore, !shouldSkipLoadingSchedule);

const container = document.getElementById('root');
const root = createRoot(container);
root.render(
	<StrictMode>
		<AuthStoreProvider value={authStore}>
			<ScheduleStoreProvider value={scheduleStore}>
				<UserConfigStoreProvider value={userConfigStore}>
					<QuicksightProvider value={quicksightStore}>
						<ConfigProvider theme={{ token: antdTheme }}>
							<Router>
								<App />
							</Router>
						</ConfigProvider>
					</QuicksightProvider>
				</UserConfigStoreProvider>
			</ScheduleStoreProvider>
		</AuthStoreProvider>
	</StrictMode>,
);
