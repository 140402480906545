import { browserHistory } from '../../../utils/history';
import { useNavinaData } from '../../../utils/useNavinaData';
import { ReactComponent as NavinaLogo } from './Logo.svg';
import { observer } from 'mobx-react-lite';
import styled from 'styled-components';

interface LogoProps {
	readonly width?: string;
	readonly height?: string;
	readonly shouldRedirectToHomePage?: boolean;
}

const LogoContainer = styled.div<{ readonly $clickable: boolean } & LogoProps>`
	display: flex;
	align-items: center;
	justify-content: center;
	cursor: ${(props) => (props.$clickable ? 'pointer' : 'default')};

	& > svg {
		inline-size: ${(props) => props.width || 'auto'};
		block-size: ${(props) => props.height || 'auto'};
	}
`;

export const Logo = observer(function Logo({
	width = 'auto',
	height = '44px',
	shouldRedirectToHomePage = true,
}: LogoProps) {
	const { isFullScreen } = useNavinaData();
	const forwardToHomePage: boolean = isFullScreen && shouldRedirectToHomePage;

	const handleLogoClick = (): void => {
		if (forwardToHomePage) {
			browserHistory.push('/');
		}
	};

	return (
		<LogoContainer $clickable={forwardToHomePage} width={width} height={height} onClick={handleLogoClick}>
			<NavinaLogo />
		</LogoContainer>
	);
});
