import { getOrCreateAuthStore } from './authStore';

// eslint-disable-next-line @typescript-eslint/no-extraneous-class -- Legacy code
export class NavinaCache {
	static genKey = (key: string) => {
		const sub = getOrCreateAuthStore().getJwtData().sub;
		if (sub) {
			return `${sub}_${key}`;
		}
	};

	static remove = (key: string): void => {
		if (this.exist(key)) {
			localStorage.removeItem(this.genKey(key));
		}
	};

	static get = (key: string): string | undefined => {
		if (this.exist(key)) {
			return localStorage.getItem(this.genKey(key));
		}

		return undefined;
	};

	static getArray = (key: string): string[] => {
		if (this.exist(key)) {
			return localStorage.getItem(this.genKey(key)).split(',');
		}

		return [];
	};

	static set = (key: string, value: any): void => {
		if (!value || value.length === 0) {
			localStorage.removeItem(this.genKey(key));
		} else {
			localStorage.setItem(this.genKey(key), value);
		}
	};

	static exist = (key: string): boolean => {
		return Boolean(localStorage.getItem(this.genKey(key)));
	};

	static getAllStorageMatchingKeys = (keyPrefix: string): string[] => {
		const keys = Object.keys(localStorage);
		const keysWithoutSubPart = keys.map((k) => k.split('_').splice(1).join('_'));
		return keysWithoutSubPart.filter((k): boolean => k.startsWith(keyPrefix));
	};
}
