import { colors } from '../base-ui/Colors';
import {
	MAX_WIDTH_LOWER_WIDTH,
	MAX_WIDTH_MEDIUM_WIDTH,
	MIN_WIDTH_LOWER_WIDTH,
	MIN_WIDTH_MEDIUM_WIDTH,
} from '../base-ui/Resolutions';
import antdTheme from './antd-tokens/light.json';
import { createGlobalStyle } from 'styled-components';

const SCROLLBAR_WIDTH = 13;

export const GlobalStyle = createGlobalStyle<{ readonly rootVariables: string }>`
	*,
	*::before,
	*::after {
		box-sizing: border-box;
	}

	.navina-hidden {
		display: none !important;
	}

	body {
		margin: 0;
		font-family: Inter, sans-serif;
		-webkit-font-smoothing: antialiased;
		-moz-osx-font-smoothing: grayscale;
		block-size: 100%;
		font-size: 1rem;
		font-weight: 400;
		line-height: 1.5;
		text-align: left;
	}

	html {
		block-size: 100%;
		font-family: sans-serif;
		line-height: 1.15;
	}

	svg {
		overflow: hidden;
		vertical-align: middle;
	}

	a {
		text-decoration: none;
		background-color: transparent;
	}

	button:focus {
		outline: none;
	}

	::selection {
		background: ${colors.accentLightGreen};
	}

	.rpv-search__highlight {
		background: ${colors.accentOrangeLight};
	}

	.rpv-search__highlight--current {
		background: ${colors.accentYellowLight};
	}

	[hidden] {
		display: none;
	}

	@supports selector(::-webkit-scrollbar) {
		::-webkit-scrollbar-track {
			background: none;
		}

		::-webkit-scrollbar {
			inline-size: ${SCROLLBAR_WIDTH}px;
			block-size: 10px;
		}

		::-webkit-scrollbar-thumb {
			background: #ccc;
			border-radius: 8px;
		}
	}

	.ph-item {
		background-color: initial;
		border: initial;
		padding: 0%;
		margin-block-end: initial;
	}

	.ph-picture {
		border-radius: 0.25rem;
		block-size: 66px;
	}

	.pdfobject-container {
		block-size: 100%
	}

	.__react_component_tooltip {
		box-shadow: 2px 2px 4px 0 #00000040;
	}

	.hide-on-small-screen {
		@media ${MAX_WIDTH_LOWER_WIDTH} {
			display: none;
		}
	}

	.hide-on-small-and-medium-screen {
		@media ${MAX_WIDTH_MEDIUM_WIDTH} {
			display: none;
		}
	}

	.hide-on-large-screen {
		@media ${MIN_WIDTH_MEDIUM_WIDTH} {
			display: none;
		}
	}

	.hide-on-large-and-medium-screen {
		@media ${MIN_WIDTH_LOWER_WIDTH} {
			display: none;
		}
	}

	.Toastify__toast-container {
		inline-size: fit-content;
		inset-block-end: 45px;
	}

	.Toastify__toast--error {
		border: 2px solid #F24E1E;
		background: #FCDCD2;
		border-radius: 15px;
		padding: 4px;
	}

	.Toastify__toast--success {
		border: 2px solid #9BDEA9;
		background: #D9F9EB;
		border-radius: 15px;
		padding: 4px;
		display: flex;
		align-items: center;
	}

	.Toastify__toast-body {
		text-overflow: ellipsis;
		overflow: hidden;
		inline-size: 100%;
		padding: 0;
	}

	.Toastify__toast-body > div {
		overflow: hidden;
	}

	.Toastify__close-button {
		align-self: center;
		margin: 0;
		color: white;
		opacity: 1;
		font-size: 12px;
		font-family: 'Inter';
		font-style: normal;
		font-weight: 500;
		line-height: 150%;
		cursor: pointer !important;
		outline: none !important;
	}

	.ant-select-dropdown {
		.ant-select-item-option-selected {
			background-color: ${colors.authInputBackground} !important;
		}

		.ant-select-item-option-active:not(.ant-select-item-option-selected) {
			background-color: ${colors.cartStroke} !important;
		}

		.ant-select-item-option-state {
			color: ${colors.secondary} !important;
		}
	}

	.anticon.anticon-info-circle.ant-alert-icon {
		color: ${colors.antInfoIconColor};
	}

	.ant-notification-notice-icon-success {
		color: ${antdTheme.colorSuccess} !important;
	}
	.ant-notification-notice {
		inline-size: auto !important;
	}

	.ant-select-item-option-selected {
		font-weight: unset !important;
	}

	.ant-table-row-expand-icon {
		color: ${antdTheme.colorPrimary} !important;
		border-radius: 2px !important;
	}

	.ant-descriptions-item-content {
		background-color: ${antdTheme.colorBgContainer} !important;
	}

	.ant-popover-title {
		margin-block-end: 12px !important;
	}
	.no-padding-popup-items-for-select-drop-down .ant-select-item {
		padding: 0;
	}
`;
