import { initFeatureFlag } from '../config/FeatureFlag';
import { getCurrentConfiguration } from '../config/config';
import { getOrCreateAddonCommunicator, OverlayProtocolTypes } from '../utils/addon/addon-communicator';
import { getOrCreateAnalytics } from '../utils/analytics';
import { getOrCreateAppcues } from '../utils/appcues';
import { Auth, SignInStatus, type UserMetadata } from '../utils/auth';
import { type CognitoUserSession } from 'amazon-cognito-identity-js';
import JwtDecode from 'jwt-decode';
import LogRocket from 'logrocket';
import { action, computed, configure, observable, makeObservable } from 'mobx';
import { createContext, useContext } from 'react';

const ANALYTICS_METADATA_JWT_KEY = 'analyticsMetadata';
const PERMISSIONS_METADATA_JWT_KEY = 'permissions';

configure({ enforceActions: 'always' });

export interface NavinaJwt {
	sub: string;
	aud: string;
	'cognito:groups': string[];
	email_verified: boolean;
	event_id: string;
	token_use: string;
	auth_time: number;
	iss: string;
	'cognito:username': string;
	exp: number;
	iat: number;
	email: string;
	analyticsMetadata: string;
	emrName?: string;
	emrUsername?: string;
	isAdmin?: string;
	permissions?: string;
}

type StringBoolean = 'true' | 'false';

export interface NavinaPermissions {
	view?: 'Coder' | 'Provider' | 'Classic';
	hcc_dashboard?: StringBoolean;
	debug_panel?: StringBoolean;
}

type AuthStoreValues = {
	email: string;
	password: string;
};

class AuthStore {
	auth: Auth;
	initializing = true;
	inProgress = false;
	loggedIn: boolean | undefined = undefined;
	token = '';
	admin = false;
	multipleProviders = false;
	status: SignInStatus = SignInStatus.initial;
	userFullName = '';
	permissions: NavinaPermissions = {};
	isSAML = false;

	values: AuthStoreValues = {
		email: '',
		password: '',
	};

	constructor() {
		makeObservable(this, {
			initializing: observable,
			inProgress: observable,
			loggedIn: observable,
			token: observable,
			admin: observable,
			multipleProviders: observable,
			status: observable,
			userFullName: observable,
			permissions: observable,
			isSAML: observable,
			values: observable,
			init: action,
			setToken: action,
			setIsSAML: action,
			setEmail: action,
			setPassword: action,
			setSignInStatus: action,
			setInProgress: action,
			setIsInitializing: action,
			setIsLoggedIn: action,
			setIsAdmin: action,
			setIsMultipleProviders: action,
			setPermissions: action,
			signInStatus: computed,
			isInProgress: computed,
			isLoggedIn: computed,
			isInitializing: computed,
			getToken: computed,
			getIsSAML: computed,
			getEmail: computed,
			isMultipleProviders: computed,
			isAdmin: computed,
			getPermissions: computed,
			reset: action,
			afterLogin: action,
			afterInit: action,
			setFullName: action,
			login: action,
			sendMFA: action,
			refresh: action,
			logout: action,
		});

		this.auth = new Auth();
	}

	init = (): void => {
		this.auth.getAuthData(this.afterInit);
	};

	setToken = (token: string): void => {
		this.token = token;
	};

	setIsSAML = (isSAML: boolean): void => {
		this.isSAML = isSAML;
	};

	setEmail = (email: string): void => {
		this.values.email = email;
	};

	setPassword = (password: string): void => {
		this.values.password = password;
	};

	setSignInStatus = (value: SignInStatus): void => {
		this.status = value;
	};

	setInProgress = (inProgress: boolean): void => {
		this.inProgress = inProgress;
	};

	setIsInitializing = (isInitializing: boolean): void => {
		this.initializing = isInitializing;
	};

	setIsLoggedIn = (isLoggedIn: boolean): void => {
		this.loggedIn = isLoggedIn;
	};

	setIsAdmin = (isAdmin: boolean): void => {
		this.admin = isAdmin;
	};

	setIsMultipleProviders = (multipleProviders: boolean): void => {
		this.multipleProviders = multipleProviders;
	};

	setPermissions = (permissions: NavinaPermissions | string): void => {
		this.permissions = typeof permissions === 'string' ? JSON.parse(permissions) : {};
	};

	get signInStatus(): typeof this.status {
		return this.status;
	}

	get isInProgress(): typeof this.inProgress {
		return this.inProgress;
	}

	get isLoggedIn(): typeof this.loggedIn {
		return this.loggedIn;
	}

	get isInitializing(): typeof this.initializing {
		return this.initializing;
	}

	get getToken(): typeof this.token {
		return this.token;
	}

	get getIsSAML(): typeof this.isSAML {
		return this.isSAML;
	}

	get getEmail(): typeof this.values.email {
		return this.values.email;
	}

	get isMultipleProviders(): typeof this.multipleProviders {
		return this.multipleProviders;
	}

	get isAdmin(): typeof this.admin {
		return this.admin;
	}

	get getPermissions(): typeof this.permissions {
		return this.permissions;
	}

	reset = (): void => {
		this.setEmail('');
		this.setPassword('');
		this.setToken('');
		this.setIsLoggedIn(false);
		this.setIsAdmin(false);
		this.setSignInStatus(SignInStatus.initial);
		this.auth.signOut(this.isSAML);
		getOrCreateAnalytics().reset();
	};

	thirdPartyAppsIdentifications = (
		navinaUserId: string,
		username: string,
		analyticsMetadata: Record<string, unknown>,
	): void => {
		// Mixpanel
		console.log('thirdPartyAppsIdentifications', navinaUserId, username, analyticsMetadata);
		if (username) {
			getOrCreateAnalytics().identify(navinaUserId, username);
		}
		getOrCreateAnalytics().register(analyticsMetadata);

		// LogRocket
		try {
			const traits: { [propName: string]: string | number | boolean } = Object.entries(analyticsMetadata)
				.filter(([_, value]): boolean => ['boolean', 'number', 'string'].includes(typeof value))
				.reduce((obj, [key, value]) => Object.assign(obj, { [key]: value }), {});

			LogRocket.identify(username || 'Unknown', traits || {});
		} catch (err) {
			console.error('Failed logrocket identification');
		}

		// Appcues
		try {
			getOrCreateAppcues().identify(navinaUserId, analyticsMetadata || {});
		} catch (err) {
			console.error('Failed Appcues identification', err);
		}

		// Datadog
		import('@datadog/browser-rum')
			.then(function initDataDogRum({ datadogRum }): void {
				datadogRum.setUser({
					id: username || 'Unknown',
					clinic: analyticsMetadata?.clinic || 'Unknown',
					emrName: analyticsMetadata?.emrName || 'Unknown',
				});
			})
			.catch((err): void => console.error('Failed Datadog identification', err));
	};

	afterLogin = (username: string, session: CognitoUserSession, status: SignInStatus): void => {
		console.log('afterLogin called', username, session, status);
		this.setSignInStatus(status);

		const analytics = getOrCreateAnalytics();

		if (status === SignInStatus.success || status === SignInStatus.totpRequired) {
			analytics.track(analytics.idsNames.LogInSuccessful, { username, status });

			const jwt: Partial<NavinaJwt> = session ? JwtDecode<NavinaJwt>(session.getIdToken().getJwtToken()) : {};
			let analyticsMetadata = {};
			try {
				const analyticsMetadataString = jwt[ANALYTICS_METADATA_JWT_KEY];
				analyticsMetadata = analyticsMetadataString ? JSON.parse(analyticsMetadataString) : {};
			} catch (err) {
				console.warn('analytics metadata corrupted');
			}

			let permissionsMetadata = {};
			try {
				const permissionsMetadataString = jwt[PERMISSIONS_METADATA_JWT_KEY];
				permissionsMetadata = JSON.parse(permissionsMetadataString);
			} catch (err) {
				console.warn('permissionsMetadata corrupted');
			}

			if (!username && this.getEmail) {
				username = this.getEmail;
			}
			const emrName = 'emrName' in jwt ? jwt.emrName : 'unknown';
			const { sub } = jwt;

			if (username) {
				this.setEmail(username);
				this.thirdPartyAppsIdentifications(sub, username, {
					...permissionsMetadata,
					...analyticsMetadata,
					emrName,
				});
			}

			const isAdmin = false;

			if (session) {
				this.setToken(session.getIdToken().getJwtToken());
				this.setIsLoggedIn(session.isValid());
				const groups: string[] = session.getIdToken()?.payload['cognito:groups'];
				const innerIsAdmin = session.getIdToken()?.payload.isAdmin === 'true';
				this.setIsAdmin(innerIsAdmin);
				this.setIsMultipleProviders(groups && groups.includes('MultipleProviders'));
			}

			initFeatureFlag(sub, username, {
				...analyticsMetadata,
				...permissionsMetadata,
				emr_name: emrName,
				is_admin: isAdmin,
			});

			this.setFullName(analyticsMetadata, username);
			this.setPermissions(jwt.permissions);
		} else {
			this.setIsLoggedIn(false);
			this.setToken('');
		}
		this.setInProgress(false);
	};

	afterInit = (
		username: string,
		session: CognitoUserSession,
		auth0Token: string,
		userMetadata: UserMetadata,
		isSSO = false,
	): void => {
		console.log('afterInit called', username, session);
		const addonCommunicator = getOrCreateAddonCommunicator();

		const analytics = getOrCreateAnalytics();

		if (username && session) {
			// Cognito login
			const jwt = JwtDecode<NavinaJwt>(session.getIdToken().getJwtToken());
			let analyticsMetadata = {};

			try {
				analyticsMetadata = JSON.parse(jwt[ANALYTICS_METADATA_JWT_KEY]);
			} catch (err) {
				console.warn('analyticsMetadata corrupted');
			}

			let permissionsMetadata = {};
			try {
				permissionsMetadata = JSON.parse(jwt[PERMISSIONS_METADATA_JWT_KEY]);
			} catch (err) {
				console.warn('permissionsMetadata corrupted');
			}

			this.setEmail(username);
			this.setToken(session.getIdToken().getJwtToken());
			this.setIsLoggedIn(session.isValid());
			this.setIsSAML(false);

			const groups: string[] = session.getIdToken()?.payload['cognito:groups'];

			const isAdmin = session.getIdToken()?.payload.isAdmin === 'true';

			this.setIsAdmin(isAdmin);
			this.setIsMultipleProviders(groups && groups.includes('MultipleProviders'));

			const emrName = 'emrName' in jwt ? jwt.emrName : 'unknown';

			this.thirdPartyAppsIdentifications(jwt.sub, username, {
				...permissionsMetadata,
				...analyticsMetadata,
				emrName,
			});

			initFeatureFlag(jwt.sub, username, {
				...analyticsMetadata,
				...permissionsMetadata,
				emr_name: emrName,
				is_admin: isAdmin,
			});

			this.setFullName(analyticsMetadata, username);
			this.setPermissions(jwt.permissions);
			analytics.track(analytics.idsNames.AuthInitDone, { username_attempt: username, auth_init_type: 'Cognito' });
		} else if (username && auth0Token) {
			// SSO Auth0 Login
			const decodedIdToken = JwtDecode<NavinaJwt>(auth0Token);
			console.log('Auth0 JWT Payload:', decodedIdToken);
			const subId = decodedIdToken.sub;
			const ssoEmail = decodedIdToken['https://navina.ai/email']; // Athena email is not Navina email, we won't use it
			const navinaUsername = userMetadata.navinaUsername;
			this.setEmail(navinaUsername);
			this.setToken(auth0Token);
			this.setIsLoggedIn(true);
			this.setIsSAML(true);
			this.setIsAdmin(userMetadata.isAdmin);
			this.setIsMultipleProviders(false);

			this.thirdPartyAppsIdentifications(subId, navinaUsername, {
				...userMetadata.permissions,
				...userMetadata.analyticsMetadata,
				emrName: userMetadata.emrName,
			});

			initFeatureFlag(subId, navinaUsername, {
				...userMetadata.analyticsMetadata,
				...userMetadata.permissions,
				emr_name: userMetadata.emrName,
				is_admin: userMetadata.isAdmin,
				sso_email: ssoEmail,
			});

			this.setFullName({}, username);
			this.setPermissions(JSON.stringify(userMetadata.permissions));

			analytics.track(analytics.idsNames.AuthInitDone, { username_attempt: ssoEmail, auth_init_type: 'SSO' });

			addonCommunicator.sendToAddon(OverlayProtocolTypes['addon-sso-login-succeed'], {});
			addonCommunicator.sendToAddon(OverlayProtocolTypes['addon-sso-login-completed'], {});

			this.setIsInitializing(false);
			this.setInProgress(false);
		} else {
			console.warn('AfterInit failed, setting isLoggedIn to false', username, session);
			this.setIsLoggedIn(false);
			this.setToken('');
		}

		if (!isSSO) {
			this.setIsInitializing(false);
			this.setInProgress(false);
		}
	};

	setFullName = (analyticsMetadata: any, username: string): void => {
		let userFullName = username;
		if ('first_name' in analyticsMetadata && 'last_name' in analyticsMetadata) {
			userFullName = `${analyticsMetadata.first_name} ${analyticsMetadata.last_name}`;
		}

		this.userFullName = userFullName;
	};

	login = (verificationCode?: string | null, callback?: (status: SignInStatus) => void): void => {
		const analytics = getOrCreateAnalytics();

		analytics.track(analytics.idsNames.LogInAttempt, { username_attempt: this.values.email });

		this.setInProgress(true);

		this.auth.signIn(
			this.values.email,
			this.values.password,
			(email: string, session: CognitoUserSession, status: SignInStatus): void => {
				this.afterLogin(email, session, status);
				callback(status);
			},
			verificationCode,
		);
	};

	sendMFA = (verificationCode: string): void => {
		this.setInProgress(true);
		this.auth.sendMFA(verificationCode, this.afterLogin.bind(this));
	};

	refresh = async (): ReturnType<typeof this.auth.refresh> => {
		console.log('user token refresh');
		this.setInProgress(true);
		const response = this.auth.refresh(this.afterLogin);
		return response;
	};

	refreshToken = async () => {
		console.log('user token refresh');
		if (this.getIsSAML && this.token) {
			const newUri = getCurrentConfiguration().NavinaAuth0AuthURL();
			this.setToken(null);
			window.location.href = newUri;
			return;
		}

		const response = this.auth.refreshToken();
		response
			.then((session): void => this.setToken(session.getIdToken().getJwtToken()))
			.catch((): void => this.setToken(null));

		return response;
	};

	logout = async (): Promise<void> => {
		getOrCreateAnalytics().track(getOrCreateAnalytics().idsNames.LogOut);
		console.log('user logout');
		this.reset();
		this.auth.signOut(this.isSAML);
		return Promise.resolve();
	};

	changePassword = (oldPassword: string, newPassword: string, callback: (err: any, result: any) => void): void => {
		this.auth.changePassword(oldPassword, newPassword, callback);
	};

	// Password init on first login, equivalent for Sign Up
	completeNewPasswordChallenge = (newPassword: string): void => {
		this.auth.completeNewPasswordChallenge(newPassword, this.refresh.bind(this));
	};

	confirmForgotPassword = (
		username: string,
		newPassword: string,
		emailCode: string,
		onSuccess: (succeed: boolean) => void,
		onFailure: (error: Error) => void,
	): void => {
		this.auth.confirmForgotPassword(username, newPassword, emailCode, onSuccess, onFailure);
	};

	requestPasswordReset = (username: string): ReturnType<typeof this.auth.requestPasswordReset> => {
		console.log('sending reset request - requestPasswordReset');
		this.setInProgress(true);
		const response = this.auth.requestPasswordReset(username);

		response.finally((): void => this.setInProgress(false));

		return response;
	};

	getJwtData = (): NavinaJwt | null => (this.token ? JwtDecode<NavinaJwt>(this.token) : null);

	getRbacGroups = (): string[] => this.getJwtData()['cognito:groups'] || [];
}

export const AuthStoreContext = createContext<AuthStore>(null);
export const useAuthStore = (): AuthStore => useContext(AuthStoreContext);

// 'authStore.init()' used to be part of the AuthStore constructor. This was causing issues with the lazy initialization of the store.
// 'if (authStore === null) {' suffered from race conditions, where the store was not yet initialized, but the check was already done.
// So more and more stores were created.
// In order to speed up the occupation of 'let authStore: AuthStore | null = null', I separated the initialization from the constructor,
// which seems to have solved the problem!
// Yet, for safety, I also added this eager initialization here.
// TODO: Consider removing this eager initialization, as it is not necessary anymore. Only courage is needed. @AvivNavina
//
// let authStore: AuthStore | null = null;
//
let authStore: AuthStore | null = createAuthStore();
authStore.init();

function createAuthStore(): AuthStore {
	return new AuthStore();
}

export type { AuthStore };

export function getOrCreateAuthStore(): AuthStore {
	if (authStore === null) {
		authStore = createAuthStore();
		authStore.init();
	}

	return authStore;
}
