import { ViewOnlyMode } from '../../base-ui/css/ViewOnlyMode';
import { ContactUs } from '../../components/contact-us/ContactUs';
import { checkIfHasPermission } from '../../components/user-permissions/HasPermission';
import { featureFlag } from '../../config/FeatureFlag';
import type { NavinaLoginStep } from '../../pages/auth/steps/NavinaLoginStepEnum';
import { useFeatureFlag } from '../../pages/summary/behaviours/useFeatureFlag';
import { useAuthStore } from '../../stores/authStore';
import type { TabId, TabsViewTabName } from '../../types';
import { getOrCreateAddonCommunicator, OverlayProtocolTypes } from '../../utils/addon/addon-communicator';
import { inIframe, loggedInViaSSo } from '../../utils/common';
import { NEXT_URL_QUERY_PARAMS, SSO_URL_QUERY_PARAMS } from '../../utils/consts';
import { getOrCreateLogger } from '../../utils/logger';
import { useNavinaData } from '../../utils/useNavinaData';
import { useUrlChanged } from '../useUrlChanged';
import type { NavinaRoute } from './RouteType';
import { observer } from 'mobx-react-lite';
import QueryString from 'query-string';
import { useEffect, useState } from 'react';
import { Redirect, Route } from 'react-router-dom';

interface ProtectedRouteProps extends NavinaRoute {
	readonly ContactUsEnabled?: boolean;
	readonly isSummaryView?: boolean;
	readonly overlayPageName?: TabsViewTabName;
	readonly defaultStepId?: NavinaLoginStep;
}

const EmptyDiv = () => <div />;

export const ProtectedRoute = observer(function ProtectedRoute({
	layout: Layout,
	component: Component,
	location,
	ContactUsEnabled = false,
	isSummaryView = false,
	...rest
}: ProtectedRouteProps) {
	useUrlChanged();

	const authStore = useAuthStore();
	const navina = useNavinaData();
	const overlayOnlyView = useFeatureFlag<boolean | undefined>('configuration_OverlayOnlyView', undefined);
	const frontendTabsConfiguration = useFeatureFlag<TabId[] | undefined>('configuration_FrontendTabs', undefined);

	const [loggedIn, setLoggedIn] = useState(authStore?.isLoggedIn);
	let queryString = QueryString.parse(window.location.search);
	const ssoUrl = queryString[SSO_URL_QUERY_PARAMS] as string;

	useEffect((): void => {
		if (authStore) {
			setLoggedIn(authStore.isLoggedIn);
		}
	}, [authStore, authStore.isLoggedIn]);

	let shouldChangeToOverlay = false;

	if (!navina.isOverlay && !window.location.pathname.includes('/overlay/')) {
		if (overlayOnlyView && isSummaryView) {
			shouldChangeToOverlay = true;
			window.location.pathname = `overlay${window.location.pathname}`;
		}
	}

	if (loggedIn) {
		if (localStorage.getItem('hadError')) {
			localStorage.removeItem('hadError');
		}

		const { flagsAreInitialized } = featureFlag();
		const isReady = flagsAreInitialized && !shouldChangeToOverlay;

		if (!isReady) {
			return <Route>{Layout ? () => <Layout component={EmptyDiv} /> : null}</Route>;
		}

		const isViewOnly = checkIfHasPermission(authStore, 'view_only', false);

		return (
			<Route>
				{(props) => {
					const layout = Layout ? (
						<Layout component={Component} {...rest} {...props} allowedTabs={frontendTabsConfiguration} />
					) : (
						<Component {...rest} {...props} allowedTabs={frontendTabsConfiguration} />
					);

					return (
						<>
							{layout}
							{isViewOnly && <ViewOnlyMode />}
							{ContactUsEnabled && <ContactUs />}
						</>
					);
				}}
			</Route>
		);
	}

	if (ssoUrl && !loggedInViaSSo()) {
		const { [SSO_URL_QUERY_PARAMS]: _removedParam, ...restQueryString } = queryString;
		queryString = restQueryString;

		const nextUrl = `${window.location.pathname}?${QueryString.stringify(queryString)}`;

		getOrCreateLogger().info('starting sso process', { ssoUrl, nextUrl });
		localStorage.setItem(NEXT_URL_QUERY_PARAMS, nextUrl);
		localStorage.setItem('ssoUrl', ssoUrl);

		const isInIframe = inIframe();

		if (isInIframe) {
			getOrCreateAddonCommunicator().sendToAddon(OverlayProtocolTypes['addon-change-frame-src'], { url: ssoUrl });
		} else {
			window.location.href = ssoUrl;
			return null;
		}
	}

	const nextUrl = `/login${location.pathname}${location.search}`;
	return <Redirect to={nextUrl} />;
});
