import type { ReactNode } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

const Container = styled.div`
	margin-block-start: 15px;
`;

interface GeneralMessageProps {
	readonly children: ReactNode;
}

export function GeneralMessage({ children }: GeneralMessageProps) {
	return <Container>{children}</Container>;
}

export function NotFoundMessage() {
	return (
		<GeneralMessage>
			<div>
				<h3>Page not found</h3>
				<hr />
				<h4>
					Sorry the page you are looking for could not be found.
					<br />
					Click <Link to='/'>here</Link> to return home.
				</h4>
			</div>
		</GeneralMessage>
	);
}
