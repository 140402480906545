import Icon, { type CustomIconComponentProps } from '@ant-design/icons/lib/components/Icon';

function QualityOfCareSvg() {
	return (
		<svg width='54' height='54' viewBox='0 0 54 54' fill='none' xmlns='http://www.w3.org/2000/svg'>
			<path
				fillRule='evenodd'
				clipRule='evenodd'
				d='M27.0004 42.9995L45.1504 23.9995L41.9951 19.0504L34.8216 14.9995L19.2968 14.9995L12.1233 19.0504L8.65039 23.9995L27.0004 42.9995ZM13.7157 26.3369L22.5004 35.4995L18.6125 27.8559L13.7157 26.3369ZM20.9349 27.9995L27.0004 39.4995L33.9481 27.9995H20.9349ZM36.1737 25.81L42.5831 23.693L40.5007 20.542L34.5767 22.6161L36.1737 25.81ZM33.0038 20.9995L38.8751 19.2407L34.3531 16.9995L19.7652 16.9995L16.2697 19.2276L21.2944 20.9995L33.0038 20.9995ZM19.8403 22.6566L13.6299 20.5359L11.3334 23.658L18.6727 25.8061L19.8403 22.6566ZM20.734 25.9995H34.0324L32.5324 22.9995H21.8462L20.734 25.9995ZM39.9614 26.5221L36.3226 27.8475L32.0004 35.4995L39.9614 26.5221Z'
				fill='#453B87'
			/>
			<path d='M33.0037 21L38.875 19.2412L34.353 17H19.7651L16.2695 19.2281L21.2942 21L33.0037 21Z' fill='#78F5CE' />
			<path d='M20.7334 26H34.0317L32.5317 23H21.8456L20.7334 26Z' fill='#98F5D8' />
			<path d='M36.1731 25.81L42.5825 23.693L40.5002 20.542L34.5762 22.6161L36.1731 25.81Z' fill='#BFF3E3' />
			<path d='M39.961 26.5225L36.3222 27.8478L32 35.4999L39.961 26.5225Z' fill='#A4E3D0' />
			<path d='M13.7148 26.3379L22.4996 35.5005L18.6117 27.8569L13.7148 26.3379Z' fill='#A4E3D0' />
			<path d='M33.9478 28H20.9346L27.0001 39.5L33.9478 28Z' fill='url(#paint0_linear_3400_109799)' />
			<path d='M19.84 22.6568L13.6296 20.5361L11.333 23.6582L18.6724 25.8063L19.84 22.6568Z' fill='#BFF3E3' />
			<path
				d='M26.7679 5.66945C27.0479 5.03141 27.9531 5.03141 28.233 5.66945L29.3955 8.31846C29.5418 8.65194 29.8579 8.8795 30.2206 8.91251L33.122 9.1766C33.8253 9.24061 34.1067 10.1181 33.5718 10.5792L31.4071 12.4455C31.1262 12.6876 31.0024 13.0653 31.0854 13.4267L31.8773 16.8749C31.9753 17.3016 31.5165 17.6392 31.1383 17.4188L28.0039 15.5924C27.6928 15.4111 27.3082 15.4111 26.997 15.5924L23.8627 17.4188C23.4844 17.6392 23.0256 17.3016 23.1236 16.8749L23.9155 13.4267C23.9985 13.0653 23.8747 12.6876 23.5939 12.4455L21.4291 10.5792C20.8942 10.1181 21.1756 9.24061 21.879 9.1766L24.7804 8.91251C25.1431 8.8795 25.4591 8.65194 25.6055 8.31846L26.7679 5.66945Z'
				fill='url(#paint1_linear_3400_109799)'
			/>
			<path
				fillRule='evenodd'
				clipRule='evenodd'
				d='M26.4082 6.48863L25.6052 8.31846C25.4589 8.65194 25.1428 8.8795 24.7802 8.91251L21.8787 9.1766C21.1754 9.24061 20.894 10.1181 21.4289 10.5792L23.5936 12.4455C23.8745 12.6876 23.9983 13.0653 23.9153 13.4267L23.1234 16.8749C23.0254 17.3016 23.4842 17.6392 23.8624 17.4188L26.9968 15.5924C27.3079 15.4111 27.6926 15.4111 28.0037 15.5924L31.138 17.4188C31.5163 17.6392 31.9751 17.3016 31.8771 16.8749L31.0852 13.4267C31.0022 13.0653 31.126 12.6876 31.4069 12.4455L33.5716 10.5792C34.1065 10.1181 33.8251 9.24061 33.1218 9.1766L30.2203 8.91251C29.8577 8.8795 29.5416 8.65194 29.3953 8.31846L28.5923 6.48863L28.2328 5.66945C27.9528 5.03141 27.0477 5.03141 26.7677 5.66945L26.4082 6.48863ZM30.9895 6.97425L30.0642 4.86578C29.0843 2.63263 25.9162 2.63263 24.9362 4.86578L24.011 6.97425L21.6974 7.18483C19.2358 7.40889 18.2508 10.48 20.123 12.094L21.8311 13.5666L21.1741 16.4272C20.6842 18.5605 22.9782 20.2489 24.8694 19.1468L27.5002 17.6138L30.1311 19.1468C32.0223 20.2489 34.3163 18.5605 33.8263 16.4272L33.1694 13.5666L34.8775 12.094C36.7497 10.48 35.7647 7.40889 33.3031 7.18483L30.9895 6.97425Z'
				fill='#EFEDF7'
			/>
			<defs>
				<linearGradient
					id='paint0_linear_3400_109799'
					x1='27.4412'
					y1='28'
					x2='27.4412'
					y2='39.5'
					gradientUnits='userSpaceOnUse'
				>
					<stop stopColor='#B8F3E1' />
					<stop offset='1' stopColor='#C8FFEE' />
				</linearGradient>
				<linearGradient
					id='paint1_linear_3400_109799'
					x1='27.0005'
					y1='-8.99995'
					x2='27.0005'
					y2='18'
					gradientUnits='userSpaceOnUse'
				>
					<stop stopColor='#7C66CF' />
					<stop offset='1' stopColor='#8A78F5' />
				</linearGradient>
			</defs>
		</svg>
	);
}

export function QualityOfCareIcon(props: Partial<CustomIconComponentProps>) {
	return <Icon component={QualityOfCareSvg} {...props} />;
}
