import AntdTokens from '../../theme/antd-tokens/light.json';

function getThemeValueByName(name: keyof typeof AntdTokens): string | number {
	return AntdTokens[name];
}

export function getThemeStringValueByName(name: keyof typeof AntdTokens): string {
	const value = getThemeValueByName(name);

	if (typeof value === 'number') {
		throw Error(
			`The value of the CSS variable '${name}' is a number, please use getCssVariableNumberValueByName instead`,
		);
	}

	return value;
}

export function getThemeNumericValueByName(name: keyof typeof AntdTokens): number {
	const value = getThemeValueByName(name);

	if (typeof value === 'string') {
		throw Error(
			`The value of the CSS variable '${name}' is a string, please use getCssVariableStringValueByName instead`,
		);
	}

	return value;
}

/**
 * @deprecated Please use getThemeStringValueByName OR getThemeNumericValueByName instead. Make sure to remove the --antd- prefix from the name. This function relays on the DOM and might not work as expected in some race condition scenarios. Please prefer to use the values directly from the theme file.
 */
export const UseCssVarByName = function getCssVarValueByName(cssVarName: string) {
	return getComputedStyle(document.body).getPropertyValue(cssVarName);
};
