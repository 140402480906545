import { IS_DEVELOPMENT, IS_PRODUCTION, IS_STAGING } from './config/Environment';

export function addFavicon(): void {
	try {
		if (!IS_PRODUCTION) {
			let link = document.querySelector<HTMLLinkElement>("link[rel~='icon']");
			const img = document.createElement('img');
			const faviconUrl = link.href || `${window.location.origin}/favicon.svg`;

			if (!link) {
				link = document.createElement('link');
				link.setAttribute('rel', 'shortcut icon');
				document.head.appendChild(link);
			}

			const onImageLoaded = (): void => {
				const canvas = document.createElement('canvas');
				canvas.width = 48;
				canvas.height = 48;

				const context = canvas.getContext('2d');
				context.drawImage(img, 0, 0);
				context.globalCompositeOperation = 'source-in';

				if (IS_STAGING) {
					context.fillStyle = '#0b31ff';
					document.title = 'Staging - Navina Summary';
				} else if (IS_DEVELOPMENT) {
					context.fillStyle = '#43fc3a';
					document.title = 'Development - Navina Summary';
				}

				context.fillRect(0, 0, 48, 48);
				context.fill();
				link.type = 'image/x-icon';
				link.href = canvas.toDataURL();
			};

			img.addEventListener('load', onImageLoaded);
			img.src = faviconUrl;
		}
	} catch (err) {
		console.warn('changing favicon color failed', err);
	}
}
