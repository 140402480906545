import { useAuthStore } from '../../stores/authStore';
import { getThemeStringValueByName } from '../../utilities/styling/UseCssVarByName';
import { getOrCreateAnalytics } from '../../utils/analytics';
import { checkIfHasPermission } from '../user-permissions/HasPermission';
import { PopoverContent } from './PopoverContent';
import { AppsIcon } from './icons/AppsIcon';
import './styles.scss';
import { Popover } from 'antd';
import { observer } from 'mobx-react-lite';
import styled from 'styled-components';

interface AppsMenuProps {
	readonly selectedPage: 'quality' | 'patient-portrait' | 'dashboard' | 'files';
}

const StyledButton = styled.div`
	display: inline-flex;
	gap: 8px;
	align-items: center;
	justify-content: center;
	color: ${getThemeStringValueByName('purple.3')};
	border-radius: 6px;
	padding: 8px;
	transition:
		background-color 0.1s,
		color 0.1s;

	&:hover {
		background-color: ${getThemeStringValueByName('purple.6')};
		color: ${getThemeStringValueByName('purple.1')};
	}
`;

export const AppsMenu = observer(function AppsMenu({ selectedPage }: AppsMenuProps) {
	const authStore = useAuthStore();
	const hasQualityPermission = checkIfHasPermission(authStore, 'quality_tool', false);
	const hasDashboardPermission = checkIfHasPermission(authStore, 'hcc_dashboard', false);
	const hasFileManagerPermission = checkIfHasPermission(authStore, 'files_manager', false);

	if (!authStore.isAdmin && !hasQualityPermission && !hasDashboardPermission && !hasFileManagerPermission) {
		console.log('No permissions to show any apps menu items', { permissions: authStore?.permissions });
		return null;
	}

	const onHoverAnalytic = (): Promise<void> =>
		getOrCreateAnalytics().track(getOrCreateAnalytics().idsNames.SummaryClick, {
			actionId: 'AppMenuHover',
			SourcePage: selectedPage,
		});

	return (
		<Popover
			placement='bottomLeft'
			overlayClassName='navina-header-apps-menu-popover'
			content={<PopoverContent selectedPage={selectedPage} />}
			trigger='hover'
		>
			<StyledButton onMouseEnter={onHoverAnalytic}>
				<AppsIcon />
			</StyledButton>
		</Popover>
	);
});
