import { useUrlChanged } from '../useUrlChanged';
import type { NavinaRoute } from './RouteType';
import { observer } from 'mobx-react-lite';
import { Route } from 'react-router-dom';

interface AnyoneRouteProps extends NavinaRoute {
	readonly spinningForever?: boolean;
}

export const AnyoneRoute = observer(function AnyoneRoute({
	layout: Layout,
	component: Component,
	...rest
}: AnyoneRouteProps) {
	useUrlChanged();

	return <Route {...rest} render={(props) => <Layout component={Component} {...rest} {...props} />} />;
});
