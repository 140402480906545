import { type NavinaConfiguration, ConfigurationName } from './NavinaConfiguration';
import { JsonServerConfiguration } from './application-configurations/JsonServerConfiguration';
import { LocalServerConfiguration } from './application-configurations/LocalServerConfiguration';
import { ProductionConfiguration } from './application-configurations/ProductionConfiguration';
import { StagingConfiguration } from './application-configurations/StagingConfiguration';
import { TestConfiguration } from './application-configurations/TestConfiguration';

const CONFIGURATION_NAME = process.env.REACT_APP_CONFIGURATION_NAME ?? '';

if (!CONFIGURATION_NAME.trim()) {
	throw Error('process.env.REACT_APP_CONFIGURATION_NAME is not defined or empty');
}

export const configurations = {
	test: TestConfiguration,
	production: ProductionConfiguration,
	staging: StagingConfiguration,
	development: StagingConfiguration,
	jsonServer: JsonServerConfiguration,
	local: LocalServerConfiguration,
} as const satisfies Record<ConfigurationName, NavinaConfiguration>;

function isStringIsConfigurationName(value: string): value is ConfigurationName {
	const configurationNames: string[] = Object.values(ConfigurationName);
	return configurationNames.includes(value);
}

let maybeCurrentConfiguration: NavinaConfiguration | null = null;

export function getCurrentConfiguration(): NavinaConfiguration {
	if (!isStringIsConfigurationName(CONFIGURATION_NAME)) {
		throw Error(`'${CONFIGURATION_NAME}' is not a valid configuration name.`);
	}

	if (maybeCurrentConfiguration === null) {
		maybeCurrentConfiguration = configurations[CONFIGURATION_NAME];
	}

	return maybeCurrentConfiguration;
}
